<template>
  <div class="key-word-reply">
    <div v-if="!edit.flag" class="display-div">
      <div class="top-toolbar">
        <div class="search-div">
          <el-input
            style="width: 70%;margin-right: 1rem;"
            v-model="searchToken"
            type="text"
            suffix-icon="el-icon-search"
            size="mini"
            placeholder="输入标题/关键字进行过滤"
          />
          <el-button :loading="replyData.loading" type="primary" size="mini" icon="el-icon-search" @click="loadReplyList(1)">查询</el-button>
        </div>

        <div class="btns">
          <el-button type="success" size="small" icon="el-icon-plus" @click="doAdd" v-power="`40-20-10`"
            >新增规则</el-button
          >
        </div>
      </div>

      <el-table
        v-loading="replyData.loading"
        size="mini"
        :data="replyData.rows"
        header-row-class-name="table-header"
        :row-class-name="rowClassNameSelector"
      >
        <el-table-column type="index" label="序号" align="left" />
        <el-table-column prop="title" label="标题" align="left" />
        <el-table-column prop="keywords" label="关键字" align="left" />
        <el-table-column prop="contentDesc" label="回复内容" align="left" />
        <el-table-column prop="_caption.flag" label="状态" align="left" />
        <el-table-column prop="_caption.createdBy" label="创建人" align="left" />
        <el-table-column prop="createdTime" label="创建时间" align="left" />
        <el-table-column prop="_caption.updatedBy" label="更新人" align="left" />
        <el-table-column prop="updatedTime" label="更新时间" align="left" />
        <el-table-column label="操作" header-align="left" >
          <template slot-scope="scope">
            <el-button type="text" @click="editReply(scope)" >修改</el-button>
            <el-button type="text" :loading="disabling" @click="disableReply(scope)" >停用</el-button>
            <el-button type="text" :loading="enabling" @click="enableReply(scope)" >启用</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        background
        layout="total, prev, pager, next"
        :current-page="replyData.pageNo"
        :total="replyData.total"
        @current-change="pageChanged"
      >
      </el-pagination>
    </div>
    <div v-if="edit.flag" class="edit-div">
      <p class="edit-title">
        关键词回复<i class="el-icon-arrow-right" /><span style="color: #409eff">{{
          this.edit.id > 0 ? "编辑回复" : "添加回复"
        }}</span>
      </p>
      <wx-auto-reply-editor :current-id="edit.id" :reply-type="3" @cancel="cancelEdit" @saved="saved" />
    </div>
  </div>
</template>

<script>
import commonComputeds from "@/utils/commonComputeds";

export default {
  name: "keyword-reply",
  data() {
    return {
      enabling: false,
      disabling: false,
      searchToken: null,
      edit: {
        flag: false,
        id: null
      },
      replyData: {
        loading: false,
        rows: [],
      },
    };
  },
  computed: {
    ...commonComputeds,
  },
  methods: {
    async enableReply({row}) {
      try {
        this.enabling = true;
        await this.$http.doApi('fw-wx-auto-reply-enable', {id:row.id});
        this.$message.success('启用成功');
        this.loadReplyList(this.replyData.pageNo);
      } finally {
        this.enabling = false;
      }
    },
    async disableReply({row}) {
      try {
        this.disabling = true;
        await this.$http.doApi('fw-wx-auto-reply-disable', {id:row.id});
        this.$message.success('停用成功');
        this.loadReplyList(this.replyData.pageNo);
      } finally {
        this.disabling = false;
      }
    },
    editReply({row}) {
      this.edit.id = row.id;
      this.edit.flag = true;
    },
    saved() {
      this.edit.flag = false;
      this.loadReplyList(this.replyData.pageNo);
    },
    cancelEdit() {
      this.edit.id = null;
      this.edit.flag = false;
    },
    doAdd() {
      this.edit.id = 0;
      this.edit.flag = true;
    },
    async pageChanged(newPageNo) {
      this.loadReplyList(newPageNo);
    },
    async loadReplyList(pageNo) {
      if (this.noApp) {
        return;
      }
      this.replyData.loading = true;
      try {
        let params = {};
        if (this.searchToken) {
          params.searchToken = this.searchToken;
        }
        this.replyData = await this.$http.doApi(
          "fw-wx-auto-reply-list",
          { pageNo, pageSize: 20, wrapper:true },
          params
        );
      } catch (e) {
        console.log(e);
      } finally {
        this.replyData.loading = false;
      }
    },
    rowClassNameSelector({row}) {
      if (row.flag === 0) {
        return 'invalid-row';
      }
      return null;
    }
  },
  created() {
    this.$eventBus.$on("currentAppChanged", () => {
      // 取消编辑状态
      this.cancelEdit();
      // 刷新数据列表
      this.loadReplyList(1);
    });
    this.loadReplyList(1);
  },
  components: {
    'wx-auto-reply-editor': () => import('./WxAutoReplyEditor')
  }
};
</script>

<style lang="less">
.key-word-reply {
  .display-div {
    .top-toolbar {
      display: flex;
      margin-bottom: 1rem;

      .search-div {
        flex: 2;
      }

      .btns {
        flex: 6;
        text-align: right;
      }
    }

    .invalid-row {
      background: #ebeaea;
      color: #8d8d8d;
    }

    .table-header {
      color: black;
      th {
        background-color: #f5f5f6;
      }
    }

    .pagination {
      text-align: center;
      margin-top: 1rem;
    }
  }

  .edit-div {
    .edit-title {
      margin: 1rem;
      margin-top: 0px;
    }
  }
}
</style>